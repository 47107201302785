<template>
   <div id="betby"></div>
</template>

<script>
import axios from '@/lib/myAxios';
import { mapGetters,mapActions } from 'vuex';
 
export default {
  name: 'BetBy',
  components: {
    
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage'])
  },
  setup() {
  
  },
  async created() {
      
  },
  mounted(){
    this.init();
  },
  unmounted(){
   if(this.bt)
   this.bt.kill()
  },
  data() {
    return {
      bt:null,
      route:this.$router
    }
      
  },
  
  methods: {
    isMobile() {
      console.log(window.innerWidth < 768);
      return window.innerWidth < 768;
    },
    detectFocusOut() {
        console.log("It is here");
        var inView = false;
        window.onfocus = window.onblur = window.onpageshow = window.onpagehide = (e) => {
            if ({ focus: 1, pageshow: 1 }[e.type]) {
                if (inView) return;
                this.tabFocus = true;
                inView = true;
            } else if (inView) {
                this.tabFocus = !this.tabFocus;
                inView = false;
                this.bt.kill();
            }
        };
    },
    async init() {
      var component =this;
      if(!this.isLoggedIn ||  this.me.jwt==undefined || this.me.jwt =='' )
        await this.fetchUser();
        this.bt = new BTRenderer().initialize(this.getsettings());
    },
    getsettings()
    {
      var obj = this;
      
      return {
          brand_id: import.meta.env.VITE_BETBY_BRAND_ID,
          token: (this.isLoggedIn && this.me.jwt!=undefined && this.me.jwt !='')?this.me.jwt:null,
          onTokenExpired: function() {
             
            return obj.getnewJWT();
          },
          themeName: "demo-green-dark-table",
          lang: "chr",
          target: document.getElementById('betby'),
          betSlipOffsetTop: (obj.isMobile())?60: 0,
          betslipZIndex: 999,
          betSlipOffsetRight:(obj.isMobile())?0:10,
          betSlipOffsetLeft:(obj.isMobile())?0:10,
          onRouteChange: function() {  console.log('onRouteChange') },
          onLogin: function() { 
            console.log('login'); obj.login();
          },
          onRegister: function() {  console.log('onRegister') },
          onSessionRefresh: function() { 
           
            obj.bt.initialize(obj.getsettings());
          },
          onRecharge:function(){
            obj.openWallet();
          },
          stickyTop:(obj.isMobile())?70:80,
          onBetSlipStateChange: function() {  console.log('onBetSlipStateChange')},
         basename: '/sportsbook'
        }
    },
    openWallet()
    {
    
      this.route.push({ path: window.location.pathname, query: { tab: 'bank' } });
    },
    async login()
    { 
      if(!this.isLoggedIn)
        this.route.push({ path: window.location.pathname , query: { tab: 'login' } });
      else{ 
         await this.fetchUser();
         this.init();
      }
    },
    register()
    {
      this.route.push({ path: window.location.pathname , query: { tab: 'register' } })
    },
    getnewJWT()
    {
      return new Promise((resolve,reject)=>{
                axios.get('getjwt').then((data)=>{
                console.log(data)
                if(data.data.token != undefined && data.data.token)
                resolve(data.data.token);
              }).catch(()=>{
                reject('some error while fetching token');
              })
      });
    },
    ...mapActions([ 'fetchUser']),  
  },
  
  
};
</script>

<style scoped></style>
