<template>
  <technical-warning
    :wtitle="'Please contact Live Support on the left hand side of Chanced.'"
    v-if="
      !me.can_purchase /* me.username!=null && me.username!='stagetester' &&me.username != 'zangtop1' && me.username != 'Smite' && me.username != 'vishal123' */
    " />

  <div v-else class="w-full flex flex-col text-slate-300">
    <div v-if="!showPayments" class="">
      <div class="flex flex-col gap-2 p-4 pt-2 sm:pt-4">
        <span class="font-bold text-2xl text-center sm:text-3xl">Bundles</span>
        <span class="text-center"
          >Please choose one of the following bundles options</span
        >
      </div>
      <div class="grid min-[350px]:grid-cols-2 md:grid-cols-3 gap-4 grid-cols-1">
        <div
          v-for="(bundle, i) in bundles"
          class="flex flex-col rounded-xl bg-secondary  "
          :key="i"  :class="(isDisabled(bundle.price))?'opacity-50':''" >
          <div
            class="flex flex-row relative mt-4 ml-8 py-0.5 bg-secondary-light text-sm max-[480px]:ml-4 max-[480px]:text-[12px] " >
            <img
              alt="USD Coin"
              class="h-8 w-8 absolute transform -translate-y-1/2 top-1/2 -left-2 max-[480px]:w-5 max-[480px]:h-5"
              src="../../assets/coins/cash.png" />
            <span class="ml-8 max-[480px]:ml-5"
              >{{ bundle.price }} free Sweeps Coins</span
            >
          </div>
          <div class="flex flex-col items-center justify-center p-2  "  >
            <img
              class="w-24 max-[480px]:w-16 max-[480px]:mb-2"
              :src="bundle.img"
              alt="coins" />
            <span>{{ (bundle.price * 10000).toLocaleString() }}</span>
            <span class="text-xs text-primary-text">Gold Coins</span>
            <button
              v-on:click="onBundleClicked(bundle)"
              :disabled="isOnBreak || isDisabled(bundle.price)"
              class="btn-bet bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4 max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:w-1/2 max-[575px]:text-sm max-[575px]:rounded-lg">
              ${{ bundle.price }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col items-center justify-center mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-sm">
          <div class="flex items-center justify-between">
            <h2 class="mb-4 mr-4 text-xl lg:text-2xl font-semibold">Order Summary:</h2>
            <h2 class="mb-4 text-xl lg:text-2xl font-semibold">
              &dollar; {{ this.selectedBundle.price.toFixed(2) }}
            </h2>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center justify-center mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-sm bg-secondary rounded-xl">
          <div class="mb-4"></div>
          <div class="mb-4 col-span-3 flex flex-row justify-center space-x-2">
            <img class="h-5 w-5" :src="getIcon('GC')" />
            <h2 class="text-x font-bold">
              {{ (this.selectedBundle.price * 10000).toLocaleString() }}
            </h2>
          </div>

          <div class="mb-4 col-span-3 flex flex-row justify-center space-x-2">
            <img class="h-5 w-5" :src="getIcon('SC')" />
            <h2 class="text-x font-bold font-semibold">
              {{ this.selectedBundle.price }} FOR FREE
            </h2>
          </div>
        </div>

        <div class="mx-auto w-full max-w-sm" v-bind:style="{ paddingTop: '40px' }">
          <h2 class="mb-4 mr-4 text-x font-semibold">Select Method:</h2>

          <!--<button class="mx-auto w-full max-w-sm" v-on:click="onBuyBundle(false)">
            <div
              class="flex items-center rounded-xl justify-between bg-secondary"
              v-bind:style="{ marginBottom: '20px', padding: '20px' }">
              <div class="row flex">
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Bitcoin.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Ethereum.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Litecoin.svg" />
              </div>
              <div>
                <div class="flex">
                  <h2
                    class="text-x font-bold font-semibold"
                    v-bind:style="{ paddingRight: '8px' }">
                    CRYPTO
                  </h2>
                  <img
                    src="@/assets/svg/rightarrow.svg"
                    v-bind:style="{ height: '25px', color: '#fff' }" />
                </div>
              </div>
            </div>
          </button>-->

          <!--<button class="mx-auto w-full max-w-sm" v-on:click="onBuyBundle(true)">
              <div class="flex items-center rounded-xl justify-between bg-secondary"
                  v-bind:style="{  marginBottom: '20px',padding: '20px'}">
                <div class="row flex">
                  <img class="h-8" style="padding: 2px" src="../../assets/paymentmethods/Wells.svg"/>
                  <img class="h-8" style="padding: 2px" src="../../assets/paymentmethods/bank.svg"/>
                  <img class="h-8" style="padding: 2px" src="../../assets/paymentmethods/Citi.svg"/>
                  <img class="h-8" style="padding: 2px" src="../../assets/paymentmethods/USBank.svg"/>
                   
                </div>
                <div>
                  <div class="flex" style="align-items: center;">
                    <h2 class="text-x font-bold font-semibold" v-bind:style="{paddingRight: '8px'}">Online Banking</h2>
                    <img src="@/assets/svg/rightarrow.svg" v-bind:style="{height : '25px',color : '#fff'}"/>
                  </div>
                </div>
              </div>
            </button> 
-->
          <button
            class="mx-auto w-full max-w-sm"
            v-on:click="paySafeDeposit"
            v-if="!me.is_frozen">
            <div
              class="flex items-center rounded-xl bg-secondary flex-wrap gap-[15px] justify-center"
              v-bind:style="{ marginBottom: '20px', padding: '20px' }">
              <div class="row flex">
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Visa.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Mastercard.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Amex.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Discover.svg" />
              </div>
              <div>
                <div class="flex">
                  <h2
                    class="text-x font-bold font-semibold"
                    v-bind:style="{ paddingRight: '8px' }">
                    Credit/Debit Card
                  </h2>
                  <img
                    src="@/assets/svg/rightarrow.svg"
                    v-bind:style="{ height: '25px', color: '#fff' }" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      <!--      <div class="flex flex-col items-center justify-center mt-4 lg:mt-8">-->
      <!--        <span class="text-center font-bold text-xl lg:text-2xl">Purchase Options:</span>-->
      <!--      </div>-->

      <!--      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4 lg:my-8">-->

      <!--        <button v-on:click="onBuyBundle(false)"-->
      <!--                class="flex flex-col items-center justify-center gap-2 p-4 lg:p-8 rounded-lg border border-4 border-secondary-text/60 hover:opacity-75">-->
      <!--          <img class="h-28 lg:h-48" src="../../assets/bundles/crypto_coins.png">-->
      <!--          <span class="font-bold text-3xl lg:text-5xl">Buy With Crypto</span>-->
      <!--        </button>-->

      <!--        <button v-on:click="onBuyBundle(true)"-->
      <!--                class="flex flex-col items-center justify-center gap-2 p-4 lg:p-8 rounded-lg border border-4 border-secondary-text/60 hover:opacity-75">-->
      <!--          <img class="h-28 lg:h-48" src="../../assets/bundles/AptPay_logo.png">-->
      <!--          <span class="font-bold text-3xl lg:text-5xl">Buy With ACH</span>-->
      <!--        </button>-->

      <!--        <button v-on:click="paySafeDeposit"-->
      <!--                class="flex flex-col items-center justify-center gap-2 p-4 lg:p-8 rounded-lg border border-4 border-secondary-text/60 hover:opacity-75">-->
      <!--          <img class="h-28 lg:h-48" src="../../assets/bundles/credit_card.png">-->
      <!--          <span class="font-bold text-3xl lg:text-5xl">Buy With Credit Card</span>-->
      <!--        </button>-->

      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import Arrow from '../../assets/svg/rightarrow.svg';
import Twenty from '../../assets/coins/20.png';
import Thirty from '../../assets/coins/30.png';
import Fifty from '../../assets/coins/50.png';
import Hundred from '../../assets/coins/100.png';
import TwoHundred from '../../assets/coins/200.png';
import ThreeHundred from '../../assets/coins/300.png';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import TechnicalWarning from '@/components/TechnicalWarning.vue';
import { mapActions, mapGetters } from 'vuex';
import axios from '@/lib/myAxios';

export default {
  name: 'Bundles',
  components: {
    TechnicalWarning,
    Arrow,
  },
  props: {
    showBuy: {
      default: true,
    },
    isOnBreak: {
      default: true,
    }
  },
  setup() {
    //// it should be sync with PruchaseLimitCHeck.php
    const bundles = [
      {
        price: 5,
        img: Twenty,
      },
      {
        price: 20,
        img: Thirty,
      },
      {
        price: 50,
        img: Fifty,
      },
      {
        price: 100,
        img: Hundred,
      },
      {
        price: 200,
        img: TwoHundred,
      },
      {
        price: 300,
        img: ThreeHundred,
      },
      {
        price: 400,
        img: ThreeHundred,
      },
      {
        price: 500,
        img: ThreeHundred,
      },
      {
        price: 600,
        img: ThreeHundred,
      },
    ];
    const balS = balanceStore();

    return { bundles, balS };
  },
  data() {
    return { showPayments: false, selectedBundle: null };
  },
  async created() {
    if (this.me && !this.me.username) {
      await this.fetchUser();
    }
  },
  async mounted ()
  {
    if(import.meta.env.VITE_NODE_ENV == 'production')
    {
      this.$gtm.trackEvent({
      event: 'open_wallet',
      email: this.me.email,
      });
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    onBundleClicked(bundle) {
      if (this.isOnBreak) {
        this.$toast.error('Your "Break-In-Play" is active till: '+this.me.break_till_date);
        return;
      }
      this.selectedBundle = bundle;
      this.showPayments = true;
      this.balS.bundleSelected = Math.round(bundle.price * 100) / 100;
      // this.onBuyBundle(false)
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    onBuyBundle(showBuy) {
      /*fbq('track', 'AddToCart', {
        content_name: 'bundle_selected',
        value: this.selectedBundle.price,
      });*/
      const page = showBuy ? 'buy' : 'deposit';
      if (showBuy) {
        // this.$router.push('Multipay');
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            ...{ action: 'paymentForm', bundle: this.selectedBundle.price, page },
          },
        });
      } else {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            ...{ action: 'crypto', bundle: this.selectedBundle.price, page },
          },
        });
      }
      // this.showPayments=true

      
    },
    paySafeDeposit() {
      const page = 'buy';
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...{ action: 'paysafe', bundle: this.selectedBundle.price, page },
        },
      });
      
    },
    isDisabled(amount)
      {
         
        return this.me.purchase_limit_bundles.includes(amount);
      }
  },
  computed: {
    ...mapGetters(['me']),
  },
};
</script>
