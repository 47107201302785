<template>
  <punt-modal show-close="true" size="max-w-3xl min-h-[650px] p-4 my-3">
    <template #title>
      <div class="flex flex-col">
        <span class="font-bold text-lg">Gold Coins Wallet</span>
      </div>
    </template>
    <template #body>
      <div
        class="text-sm font-medium text-center text-gray-500 w-full border-b border-line-color mt-2 sm:mt-0">
        <ul class="flex flex-wrap -mb-px space-x-2 w-full">
          <li v-for="page in pages">
            <button
              :class="{
                '!text-white rounded-t-lg border-b-2 border-success-pink': isActive(
                  page.page,
                ),
              }"
              class="inline-block px-1 py-2 sm:p-4 font-semibold text-text-dim-color"
              v-on:click="
                $router.replace({
                  path: $route.path,
                  query: { page: page.page, tab: 'bank' },
                })
              ">
              {{ page.label }}
            </button>
          </li>
        </ul>
      </div>
      <div class="flex flex-col p-4 max-[480px]:p-0">
        <section class="min-h-[480px] flex flex-col text-slate-300">
          <div class="flex flex-col mt-2 flex-grow">
            <template v-if="isActive('deposit') || isActive('buy')">
              <bundles :is-on-break="isOnBreak" v-if="action === 'bundles'" />
              <!--<deposit
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'crypto' && me.can_purchase" />-->
              <payment-form
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'paymentForm'" />
              <PaySafeIdentityVerificationForm
                :show-buy="this.activeTab.toLowerCase() === 'buy'"
                v-if="action === 'paysafe'" />
            </template>
            <withdraw v-if="isActive('redeem') || action === 'redeem'" />
            <daily-bonus :is-on-break="isOnBreak" v-if="isActive('bonus')" />
            <top-up :is-on-break="isOnBreak" v-if="isActive('topup') "  />
          </div>
        </section>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import Deposit from '@/components/Bank/Deposit.vue';
import Bundles from '@/components/Bank/Bundles.vue';
import Withdraw from '@/components/Bank/Withdraw.vue';
import Tip from '@/components/Bank/Tip.vue';
import Balance from '@/components/Bank/Balance.vue';
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ordersStore } from '@/store/pinia/ordersStore';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import { mapGetters } from 'vuex';
import DailyBonus from './DailyBonus.vue';
import TopUp from './TopUp.vue';
import PaymentForm from '@/components/Bank/PaymentForm.vue';
import PaySafeIdentityVerificationForm from '@/components/Bank/PaySafeIdentityVerificationForm.vue';
// import AptPay from "@/components/Bank/AptPay.vue";

 

export default {
  name: 'Bank',
  components: {
    PaySafeIdentityVerificationForm,
    Balance,
    Popover,
    PopoverButton,
    TopUp,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    Tip,
    Withdraw,
    Deposit,
    Bundles,
    PuntModal,
    DailyBonus,
    PaymentForm,
    // AptPay
  },
  setup() {
    const orderS = ordersStore();
    const balS = balanceStore();

    return { orderS,  balS };
  },
  data() {
    return {
      activeTab: 'deposit',
      action: 'bundles',
      activeTab: 'paymentForm',
      action: 'bundles',
      activeTab: 'paysafe',
      action: 'bundles',
      isOnBreak: false,
    };
  },
  mounted(){
  // Check if the customer is on break
    if (this.me) {
      if (this.me.break_type && (this.me.break_type == 1)) {
        this.isOnBreak = true;
      }
    }
  },
  async created() {

    await this.balS.init();
    this.orderS.selected = {
      name: this.balS.default,
      icon: getBalanceIcon(this.balS.default),
    };

  },
  computed: {
    ...mapGetters(['me', 'iAmStaff']),
    depositCurrencies() {
      return this.balS.currencies
        .filter((cur) => cur.is_crypto && !cur.parent_currency_id)
        .sort((a, b) => parseFloat(b.rank) - parseFloat(a.rank));
    },
    pages()
    {
      let pages =[
        { page: 'deposit', label: 'Buy Bundle' },
        /*{page: 'buy', label: 'Buy Gold Coins'},*/
        { page: 'redeem', label: 'Redeem' },
        { page: 'bonus', label: 'Hourly Bonus' },
       
      ];
        pages.push({ page: 'topup', label: 'Top-Up' },)
      return pages;
    }
  },
  methods: {
    isActive(tab) {
      if (['buy', 'deposit'].includes(tab.toLowerCase())) {
        return ['buy', 'deposit'].includes(this.activeTab.toLowerCase());
      }
      return this.activeTab.toLowerCase() === tab.toLowerCase();
    },
    async selectCurrency(currency) {
      try {
        await this.balS.updateDefault(currency.name);
        this.orderS.selected = currency;
      } catch (e) {}
    },
    async selectNetwork(currency) {
      this.balS.network = currency;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
  },
  watch: {
    '$route.query.tab': {
      handler: function (newState) {
        /*if (newState && pages.some(x => x.page.toLowerCase() === newState.toLowerCase())) {
          this.activeTab = newState
        } else {
          this.activeTab = 'deposit'
        }*/
      },
      deep: true,
      immediate: true,
    },
    activeTab: {
      handler: function (newState) {},
      deep: true,
      immediate: true,
    },
    '$route.query.page': {
      handler: function (newState) {
        this.activeTab = newState ? newState : 'deposit';
      },
      deep: true,
      immediate: true,
    },
    '$route.query.action': {
      handler: function (newState) {
        if (
          newState &&
          ['bundles', 'crypto', 'redeem', 'buy', 'paymentForm', 'paysafe'].some(
            (x) => x.toLowerCase() === newState.toLowerCase(),
          )
        ) {
          this.action = newState;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
