<template>
  <landing v-if="!this.isLoggedIn && this.landingpage" />
  <div
    v-else
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 md:p-4 items-center  ">
    <div class="  py-4 w-full max-[480px]:p-0">
      <BetBy />
    </div>
  </div>
</template>

<script>
 
import BetBy from '@/components/Home/BetBy.vue';
import Landing from '@/components/Home/Landing.vue';
 
 
 
import { mapGetters } from 'vuex';
 
 
 
import Search from '../Search/Search.vue';
 

 

export default {
  name: 'Home',
  components: {
 
     
    BetBy,
    Landing,
     
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage']),
  },
  setup() {
     
      
     
  },
  async created() {
   // await this.init();
  },
  data() {
    return {
      loading: true,
      
    };
  },
  methods: {
    
  },
  watch: {
    '$route.query.aid': {
      handler: function (newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
