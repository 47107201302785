<template>
  <div id="main" class="relative hero-section section">
    <div class="container flex flex-col min-[993px]:flex-row min-[993px]:h-full">
      <div
        class="relative md:flex md:flex-col min-[993px]:w-2/5 md:flex-1 min-[993px]:pt-[33vh] max-[992px]:pt-[40px] max-[768px]:pt-[0] pt-[110px]">
        <div class="z-40 w-full content-wrapper big max-w-[640px]">
          <div class="title-wrapper">
            <h1
              class="title max-[768px]:!text-[40px] max-[768px]:!leading-[40px] max-[768px]:max-w-[370px] max-[768px]:mx-[auto] max-[768px]:my-[0] max-[375px]:!text-[31px] max-[375px]:!leading-[42px] max-[375px]:max-w-[300px]">
              America's Finest <span>Social Casino</span>
            </h1>
          </div>
          <div class="free-coins small">
            <div class="free-coin-title-wrapper">
              <h3 class="title">Register now and <span>receive Free gold coins</span></h3>
            </div>
            <div class="discription">
              <p><img src="/images/home/coin.webp" alt="" />+ More!</p>
            </div>
          </div>
          <div class="discription-wrapper">
            <div class="discription max-w-[359px]">
              <p>Experience the thrill of Las Vegas from the comfort of your home!</p>
            </div>
          </div>
          <div class="btn-wrapper">
            <button
              class="btn-start"
              v-on:click="
                $router.push({ path: $route.path, query: { tab: 'register' } })
              ">
              Start winning
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="image-wrapper">
        <img class="image-1" alt="girl" src="/images/home/girl.webp" />
        <img class="image-2" src="/images/home/intro-coin.webp" alt="coin" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lady_background from '/images/home/girl.webp';

export default {
  name: 'MainSection',
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  data() {
    return {
      lady_background,
    };
  },
  methods: {
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
  },
};
</script>
