<template>
  <div
    id="latestWinner"
    class="flex flex-col latest-winner-section section min-[993px]:flex-row">
    <div class="container flex flex-col min-[993px]:flex-row">
      <div class="relative flex flex-col items-center justify-start flex-1">
        <div class="z-40 w-full content-wrapper big max-w-[640px]">
          <h3 class="title">Latest <span>winners</span></h3>
          <div class="all-games very-small">
            <h4 class="title">
              Dive into the excitement with our <span>500 games </span>
            </h4>
            <div class="discription">
              <p><img src="/images/home/GC_coin.webp" alt="" />More wins!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col items-center justify-center flex-1">
        <div
          class="grid gap-[24px] max-w-full max-h-full grid-cols-2 latest-right-slider-wrapper">
          <div class="left-col">
            <div class="swiper card-slider latest-winner-slider latest-winner-slider-up">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(bet, key) in recentBets"
                  v-bind:key="key">
                  <div class="winning-card">
                    <div class="game-info">
                      <div class="game-img"><img :src="getImageUrl(bet)" alt="" /></div>
                      <div class="game-details very-small">
                        <h4 class="title">{{ bet.title }}</h4>
                        <p class="game-sub-name">{{ bet.username }}</p>
                      </div>
                    </div>
                    <div class="latest-winning">
                      <div class="coin-img">
                        <img src="/images/home/GC_coin.webp" alt="" />
                      </div>
                      <h4 class="winning-amount">{{ bet.wager }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-col">
            <div
              class="swiper card-slider latest-winner-slider latest-winner-slider-down">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(bet, key) in recentBets2"
                  v-bind:key="key">
                  <div class="winning-card">
                    <div class="game-info">
                      <div class="game-img"><img :src="getImageUrl(bet)" alt="" /></div>
                      <div class="game-details very-small">
                        <h4 class="title">{{ bet.title }}</h4>
                        <p class="game-sub-name">{{ bet.username }}</p>
                      </div>
                    </div>
                    <div class="latest-winning">
                      <div class="coin-img">
                        <img src="/images/home/GC_coin.webp" alt="" />
                      </div>
                      <h4 class="winning-amount">{{ bet.wager }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <img src="/images/home/winner-bg.webp" alt="" />
    </div>
  </div>
</template>

<script>
//import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import Swiper, { Autoplay } from 'swiper';
import axios from '../../../lib/myAxios';
export default {
  name: 'LatestWinner',
  components: {
    //Swiper,
    ///SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  async created() {
    await this.getGcBets();
  },
  data() {
    return {
      recentBets: [],
      recentBets2: [],
    };
  },

  methods: {
    async getGcBets() {
      try {
        let data = await axios.get(`gettopgc`);
        this.recentBets = data.data;
        if (this.recentBets.length > 0) {
          const divide_factor = this.recentBets.length / 2;
          this.recentBets2 = this.recentBets.slice(divide_factor, this.recentBets.length);
          this.recentBets = this.recentBets.slice(0, divide_factor);

          setTimeout(() => {
            this.initSlider();
          }, 200);
        }
      } catch(error) {
        console.log('error while getting top gc');
      }
    },
    getImageUrl(game) {
      if (game.type == 'relax') {
        return `${location.protocol}//${location.host}/images/relax/${game.identifier}.webp`;
      }

      if (game.type == 'softswiss') {
        return `${location.protocol}//${location.host}/images/softswiss/${game.identifier}.webp`;
      }

      if (game.type == 'pragmatic') {
        return `${location.protocol}//${location.host}/images/pragmatic/${game.identifier}.webp`;
      }
    },
    initSlider() {
      const swiper = new Swiper('.latest-winner-slider-down', {
        direction: 'vertical',
        modules: [Autoplay],
        spaceBetween: 20,
        slidesPerView: '3',
        loop: true,
        centeredSlides: true,
        simulateTouch: false,
        autoplay: {
          delay: 1,
        },
        speed: 5000,
      });
      const swiper2 = new Swiper('.latest-winner-slider-up', {
        direction: 'vertical',
        modules: [Autoplay],
        spaceBetween: 20,
        slidesPerView: '3',
        loop: true,
        centeredSlides: true,
        simulateTouch: false,
        autoplay: {
          delay: 1,
          reverseDirection: true,
        },
        speed: 5000,
      });
      if (window.innerWidth > 998) {
        swiper.autoplay.stop();
        swiper2.autoplay.stop();
      }
      function isInViewport(element) {
        var rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      var scrollable = document.querySelector('.no-scrollbar > div');
      scrollable.addEventListener('scroll', function () {
        var section1 = document.getElementById('latestWinner');
        if (window.innerWidth > 998) {
          if (isInViewport(section1)) {
            swiper.autoplay.start();
            swiper2.autoplay.start();
          }
        }
      });
    },
  },
};
</script>
