<template>
  <div id="fun" class="relative h-full h-screen bg-no-repeat lg:mt-16">
    <div
      class="flex flex-col justify-center items-center absolute w-[90%] lg:w-auto top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <span
        class="text-[45px] md:text-[60px] xl:text-[70px] 3xl:text-[100px] font-bold text-center">
        PLAY FOR <span class="text-[#FFC165]">FUN</span></span
      >
      <span class="text-center text-[20px] md:text-[25px] 2xl:text-[30px]">
        Claim your coins and play for fun on our Juked Social Casino Platform. Move up
        the leaderboards, rank up, and become an absolute Juked legend!
      </span>
      <img
        class="h-20 mt-16 md:h-32 3xl:h-72 lg:block lg:h-48 3xl:h-48"
        src="/images/spyglass.webp" />
    </div>
    <div
      class="hidden bg-money-sm md:bg-cash-md bg-no-repeat bg-cover min-h-[600px] relative">
      <img
        class="absolute mt-4 transform -translate-x-1/2 -translate-y-1/2 h-78 top-1/4 left-1/2"
        src="/images/spyglass.webp" />
    </div>
    <!-- Top  -->
    <!-- Top Right -->
    <img
      class="absolute w-[20%] lg:w-[15%] xl:w-[15%] top-[55%] md:top-[15%] right-0"
      src="../../../assets/home/fun/tr.png" />
    <img
      class="absolute w-[20%] md:w-[10%] xl:w-[10%] top-[65%] md:top-[40%] right-[10%]"
      src="../../../assets/home/fun/tr1.png" />

    <!-- Bottom  -->
    <!-- Bottom Right -->
    <img
      class="absolute w-[25%] xl:w-[18%] bottom-[2%] right-0"
      src="../../../assets/home/fun/br.png" />
    <img
      class="absolute w-[18%] hidden xl:block xl:w-[18%] bottom-[2%] right-[20%]"
      src="../../../assets/home/fun/br1.png" />

    <!-- Bottom Center -->
    <img
      class="absolute w-[35%] md:w-[25%] xl:w-[18%] bottom-[2%] right-[35%]"
      src="../../../assets/home/fun/bc1.png" />
    <img
      class="absolute hidden xl:block w-[18%] xl:w-[18%] bottom-[2%] left-[30%]"
      src="../../../assets/home/fun/bc2.png" />

    <!-- Center  -->
    <img
      class="absolute w-[10%] xl:w-[10%] bottom-[20%] left-[35%] 2xl:left-[45%]"
      src="../../../assets/home/fun/c.png" />

    <!-- Center Left -->
    <img
      class="absolute block lg:hidden xl:block w-[25%] lg:w-[20%] xl:w-[20%] top-[70%] lg:top-[65%] xl:top-[50%] left-[1%] lg:left-[20%]"
      src="../../../assets/home/fun/cl1.png" />
    <img
      class="absolute hidden xl:block w-[10%] xl:w-[10%] bottom-[35%] 2xl:bottom-[30%] left-[10%]"
      src="../../../assets/home/fun/cl2.png" />
    <img
      class="absolute block xl:hidden w-[30%] lg:w-[20%] xl:w-[10%] bottom-[25%] lg:bottom-[30%] left-[20%] lg:left-[30%]"
      src="../../../assets/home/fun/cl3.png" />

    <!-- Center Right -->
    <img
      class="absolute hidden lg:block w-[25%] xl:w-[10%] top-[50%] right-[20%]"
      src="../../../assets/home/fun/cr.png" />
    <img
      class="absolute hidden lg:block w-[25%] xl:w-[15%] bottom-[15%] right-[20%] xl:right-[30%]"
      src="../../../assets/home/fun/cr1.png" />
    <img
      class="absolute w-[15%] xl:w-[15%] top-[40%] right-0 block xl:hidden"
      src="../../../assets/home/fun/cr2.png" />

    <!-- Left  -->
    <!-- Top Left -->
    <img
      class="absolute w-[25%] lg:w-[15%] 2xl:w-[15%] top-[40%] xl:top-[15%] left-0"
      src="../../../assets/home/fun/tl.png" />
    <img
      class="absolute hidden lg:block w-[20%] xl:w-[12%] top-[30%] left-[15%]"
      src="../../../assets/home/fun/tl1.png" />

    <!-- bottom Left -->
    <img
      class="absolute w-[30%] lg:w-[25%] xl:w-[18%] bottom-[2%] lg:bottom-[30%] xl:bottom-[2%] left-0"
      src="../../../assets/home/fun/bl.png" />
    <img
      class="hidden xl:block absolute w-[25%] xl:w-[20%] bottom-[2%] left-[25%] xl:left-[12%]"
      src="../../../assets/home/fun/bl1.png" />
    <img
      class="block xl:hidden absolute w-[25%] xl:w-[20%] bottom-[15%] left-[40%] lg:left-[15%]"
      src="../../../assets/home/fun/bl2.png" />
  </div>
</template>

<script>
export default {
  name: 'PlayForFun',
};
</script>
